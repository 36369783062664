import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import {useTranslation} from 'react-i18next';
import tw, {styled} from 'twin.macro';
import {Container} from '../components/Grid';
import Layout from '../components/Layout';
import TermsCzech from '../components/Page/terms-and-conditions/TermsCzech';
import TermsEnglish from '../components/Page/terms-and-conditions/TermsEnglish';
import SEO from '../components/Seo';

const StyledContainer = styled(Container)`
  max-width: 1180px;
  margin-top: ${props => props.theme.mobileHeaderHeight};
  padding-top: 60px;

  @media ${props => props.theme.screens.md} {
    margin-top: ${props => props.theme.headerHeight};
  }

  a {
    ${tw`text-primary font-bold underline`}
  }

  h1 {
    ${tw`text-primary mb-8 text-center`}
    font-size: 32px;
  }

  h3 {
    ${tw`text-primary mb-4`}
    font-size: 32px;
  }

  h4 {
    ${tw`font-medium mb-4 text-center`}
  }

  p {
    ${tw`font-medium mb-4`}
  }

  ul {
    ${tw`mb-4`}
    list-style: disc;
    padding-left: 50px;
  }

  li {
    ${tw`font-medium`}
  }
`;

const TermsAndConditions = () => {
  const {t} = useTranslation();
  const {language} = useI18next();

  return (
    <Layout>
      <SEO title={t('seo_title')} description={'seo_description'} />
      <StyledContainer>
        <h1>{t('terms_and_conditions_seo_title')}</h1>
        <h4>
          <strong>{t('terms_and_conditions_seo_description')}</strong>
        </h4>
        {language === 'en' ? <TermsEnglish /> : <TermsCzech />}
      </StyledContainer>
    </Layout>
  );
};

export default TermsAndConditions;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {language: {eq: $language}, ns: {in: ["common", "seo_terms"]}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
